export default {
    iSurveys: {
        type: {
            1: '调研',
            2: '临床'
        },
        // 审核状态
        reviewStatus: {
            0: '暂存',
            1: '审核中',
            2: '已通过', // 已报名
            3: '未通过',
            4: '已完成',
            10: '待核销',
            11: '核销待审核',
            12: '核销通过',
            13: '核销驳回',
			99: '已作废'
        },
        reviewStatusTag: {
            0: '',
            1: 'warning',
            2: 'success',
            3: 'error',
            4: 'success',
            10: 'warning',
            11: 'warning',
            12: 'success',
            13: 'error',
        }
    },
    activity: {
        runStatus: {
            1: '未开始',
            2: '进行中',
            3: '已结束'
        }
    }
}
